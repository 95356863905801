// frontend/src/App.js
import React, { useEffect, useContext, useMemo, useState } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Settings from './pages/Settings';
import ResearchWizard from './pages/ResearchWizard';
import ResearchDetail from './pages/ResearchDetail';
import TelegramLogin from './pages/TelegramLogin';
import TelegramWebAppAuth from './pages/TelegramWebAppAuth';
import { ThemeProvider, CssBaseline } from '@mui/material';
import ErrorBoundary from './components/ErrorBoundary';
import { AppContext } from './context/AppContext';
import ProtectedRoute from './components/ProtectedRoute';
import Layout from './components/Layout';
import Notification from './components/Notification';
import getTheme from './theme';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isTelegramWebApp, themeMode } = useContext(AppContext);
  const [currentTheme, setCurrentTheme] = useState('light');

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      if (['/login', '/telegram-webapp-auth'].includes(location.pathname)) {
        navigate('/');
      }
    } else {
      if (isTelegramWebApp && location.pathname !== '/telegram-webapp-auth') {
        navigate('/telegram-webapp-auth');
      } else if (!isTelegramWebApp && location.pathname !== '/login') {
        navigate('/login');
      }
    }
  }, [navigate, location, isTelegramWebApp]);

  const determineTheme = () => {
    const hour = new Date().getHours();
    return hour >= 6 && hour < 18 ? 'light' : 'dark';
  };

  useEffect(() => {
    if (themeMode === 'auto') {
      setCurrentTheme(determineTheme());
      const now = new Date();
      let nextSwitch = new Date();
      nextSwitch.setHours(currentTheme === 'light' ? 18 : 6, 0, 0, 0);
      if (nextSwitch <= now) nextSwitch.setHours(nextSwitch.getHours() + 12);
      const timeout = nextSwitch.getTime() - now.getTime();
      const timer = setTimeout(() => setCurrentTheme(determineTheme()), timeout);
      return () => clearTimeout(timer);
    } else {
      setCurrentTheme(themeMode);
    }
  }, [themeMode, currentTheme]);

  const theme = useMemo(() => getTheme(currentTheme), [currentTheme]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorBoundary>
        <Routes>
          <Route path="/login" element={<TelegramLogin />} />
          <Route path="/telegram-webapp-auth" element={<TelegramWebAppAuth />} />
          <Route
            path="/*"
            element={
              <ProtectedRoute>
                <Layout>
                  <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/research-wizard" element={<ResearchWizard />} />
                    <Route path="/research/:researchId" element={<ResearchDetail />} />
                  </Routes>
                </Layout>
              </ProtectedRoute>
            }
          />
        </Routes>
        <Notification />
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
