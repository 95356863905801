// frontend/src/pages/messages.js

const messagesStock = [
    'Узнайте, почему клиенты выбирают конкурентов. 🔍',
    'Выявляем скрытые возможности на рынке. 🌟',
    'Данные, которые сделают ваш бизнес неуязвимым. 🛡️',
    'Покорите рынок, пока конкуренты спят. 🌅',
    'Секреты успешных компаний — теперь в ваших руках. 🧠',
    'Аналитика, которая умнее любых догадок. 🤓',
    'Сравните себя с лидерами рынка за минуту. ⚡',
    'Найдите золотую жилу в данных о конкурентах. 💰',
    'Конкуренты удивлены, вы — впереди. 🏆',
    'Секреты рынка — без лишних усилий. 🔑',
    'Превратите данные в оружие успеха. ⚔️',
    'Знаете их слабости? А мы — да. 💡',
    'Решения, основанные на реальных данных. 📊',
    'Ищете вдохновение? Вот оно! 🚀',
    'Пока другие гадают, вы точно знаете! 🎯',
    'Прозрачный рынок. Узнайте всё первыми. 🕶️',
    'Секретное оружие против конкурентов. 🤫',
    'Ваш рычаг влияния на рынок. 🏗️',
    'Аналитика, которая делает вас быстрее. 🏃‍♀️',
    'Конкуренты в шоке: вы на шаг впереди. 🥇',
    'Бизнес, который предсказывает тренды. 🔮',
    'Ваши решения — как хирургический скальпель. ⚙️',
    'Данные, которые работают на вас 24/7. 🕔',
    'Изучаем конкурентов, пока вы занимаетесь бизнесом. 🧑‍💼',
    'Узнайте, что работает у них, и сделайте лучше. 💥',
    'Управляйте рынком с помощью данных. 🎛️',
    'Рынок — это шахматы, а вы — гроссмейстер. ♟️',
    'Стройте стратегию на основе фактов. 📈',
    'Узнайте их ходы до того, как они их сделают. ♠️',
    'Аналитика, которая меняет правила игры. 🌀',
    'Ваши инсайты — их головная боль. 🤯',
    'Видите возможности, где другие видят риск. 🚦',
    'Разгадайте, почему их выбирают, и сделайте круче. 🔧',
    'Собирайте рынок, как пазл. 🧩',
    'Действуйте, основываясь на данных, а не на догадках. 🧠',
    'Не упускайте тренды — будьте первым. 🏁',
    'Ищем идеи там, где другие не смотрят. 🔎',
    'Найдите слабые места конкурентов. 🎯',
    'Погружайтесь в глубину аналитики без риска утонуть. 🌊',
    'Анализ данных? Просто и эффективно. 📂',
    'Узнайте, кто поднимает рынок, а кто падает. 📉',
    'Превратите хаос данных в порядок. 🔗',
    'Успешные решения — на расстоянии клика. 🖱️',
    'Постройте конкурентное преимущество за один вечер. 🌌',
    'Ваша уверенность в завтрашнем дне — наша миссия. 🛡️',
    'Лидеры рынка? Вы о них знаете всё! 📜',
  ];
  
  // Функция для перемешивания массива (метод Фишера-Йетса)
  const shuffleArray = (array) => {
    const arr = [...array];
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr;
  };
  
  // Перемешивание массива перед экспортом
  const messages = shuffleArray(messagesStock);
  
  export default messages;
  