import React, { useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Chip,
  Button,
  TextField,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const OrganizationStep = ({ orgs, selectedOrg, onSelectOrg, onCreateOrg, onUpdateOrg }) => {
  // Состояния для модальных окон создания и редактирования
  const [openNew, setOpenNew] = useState(false);
  const [newOrgData, setNewOrgData] = useState({
    company_name: '',
    company_inn: '',
    industry: '',
    position: 'Директор',
  });

  const [openEdit, setOpenEdit] = useState(false);
  const [editOrgData, setEditOrgData] = useState({});

  const handleOpenNew = () => {
    setNewOrgData({ company_name: '', company_inn: '', industry: '', position: 'Директор' });
    setOpenNew(true);
  };

  const handleSaveNew = async () => {
    await onCreateOrg(newOrgData);
    setOpenNew(false);
  };

  const handleEdit = (org) => {
    if (!org.is_editable) return;
    setEditOrgData(org);
    setOpenEdit(true);
  };

  const handleSaveEdit = async () => {
    await onUpdateOrg(editOrgData.org_id, editOrgData);
    setOpenEdit(false);
  };

  return (
    <Box>
      <Typography variant="h6" mb={2}>
        Выберите организацию
      </Typography>
      {orgs.length ? (
        <Box display="flex" flexWrap="wrap" gap={2}>
          {orgs.map((org) => (
            <Card
              key={org.org_id}
              onClick={() => onSelectOrg(org.org_id)}
              sx={{
                width: { xs: '100%', sm: 280 },
                cursor: 'pointer',
                border:
                  selectedOrg === org.org_id
                    ? '2px solid #1976d2'
                    : '1px solid #e0e0e0',
                borderRadius: 2,
                boxShadow: selectedOrg === org.org_id ? 4 : 1,
                position: 'relative',
                transition: 'all 0.2s',
                '&:hover': { boxShadow: 6 },
              }}
            >
              <CardContent>
                <Typography variant="subtitle1" fontWeight="bold">
                  {org.company_name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {org.industry}
                </Typography>
                {org.company_inn && (
                  <Chip label={`ИНН: ${org.company_inn}`} size="small" sx={{ mt: 1 }} />
                )}
              </CardContent>
              {org.is_editable && (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEdit(org);
                  }}
                  size="small"
                  sx={{ position: 'absolute', top: 8, right: 8 }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              )}
            </Card>
          ))}
        </Box>
      ) : (
        <Typography>Нет доступных организаций</Typography>
      )}
      <Box mt={2}>
        <Button variant="contained" onClick={handleOpenNew}>
          Добавить организацию
        </Button>
      </Box>

      {/* Диалог создания новой организации */}
      <Dialog open={openNew} onClose={() => setOpenNew(false)}>
        <DialogTitle>Новая организация</DialogTitle>
        <DialogContent>
          <TextField
            label="Название организации"
            fullWidth
            margin="dense"
            value={newOrgData.company_name}
            onChange={(e) =>
              setNewOrgData({ ...newOrgData, company_name: e.target.value })
            }
          />
          <TextField
            label="ИНН"
            fullWidth
            margin="dense"
            value={newOrgData.company_inn}
            onChange={(e) =>
              setNewOrgData({ ...newOrgData, company_inn: e.target.value })
            }
          />
          <TextField
            label="Отрасль"
            fullWidth
            margin="dense"
            value={newOrgData.industry}
            onChange={(e) =>
              setNewOrgData({ ...newOrgData, industry: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenNew(false)}>Отмена</Button>
          <Button variant="contained" onClick={handleSaveNew}>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>

      {/* Диалог редактирования организации */}
      <Dialog open={openEdit} onClose={() => setOpenEdit(false)}>
        <DialogTitle>Редактирование организации</DialogTitle>
        <DialogContent>
          <TextField
            label="Название организации"
            fullWidth
            margin="dense"
            value={editOrgData.company_name || ''}
            onChange={(e) =>
              setEditOrgData({ ...editOrgData, company_name: e.target.value })
            }
          />
          <TextField
            label="ИНН"
            fullWidth
            margin="dense"
            value={editOrgData.company_inn || ''}
            onChange={(e) =>
              setEditOrgData({ ...editOrgData, company_inn: e.target.value })
            }
          />
          <TextField
            label="Отрасль"
            fullWidth
            margin="dense"
            value={editOrgData.industry || ''}
            onChange={(e) =>
              setEditOrgData({ ...editOrgData, industry: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEdit(false)}>Отмена</Button>
          <Button variant="contained" onClick={handleSaveEdit}>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default OrganizationStep;
