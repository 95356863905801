// frontend/src/pages/ResearchDetail.js
import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Container,
  Typography
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

import { AppContext } from '../context/AppContext';
import { getResearchById } from '../services/api';

// Cписок статусов, при которых еще идет оформление заявки
const FORM_STATUSES = [
  'Отправлено на проверку',
  'Ошибки в заявке',
  'Сборка дизайна исследования',
  'Ожидает подтверждения'
];

const ResearchDetail = () => {
  const { researchId } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(AppContext);

  // initialLoading — для того, чтобы не было мерцания
  const [initialLoading, setInitialLoading] = useState(true);
  // локальная "загрузка" (если нужно при повторных запросах)
  const [loading, setLoading] = useState(false);

  const [research, setResearch] = useState(null);

  useEffect(() => {
    // если пользователь не авторизован, сразу прекращаем загрузку (или редирект)
    if (!user) {
      setInitialLoading(false);
      return;
    }

    setLoading(true);
    getResearchById(researchId)
      .then((resp) => {
        const data = resp.data;
        // Если статус в списке FORM_STATUSES — редиректим обратно в Wizard
        if (FORM_STATUSES.includes(data.status)) {
          navigate(`/research-wizard?edit_id=${researchId}`, { replace: true });
        } else {
          setResearch(data);
        }
      })
      .catch((err) => {
        console.error(err);
        // Можно вывести уведомление или текст ошибки
      })
      .finally(() => {
        setLoading(false);
        setInitialLoading(false); // первичная загрузка окончена
      });
  }, [user, researchId, navigate]);

  // Пока initialLoading === true => показываем только спиннер
  if (initialLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="70vh">
        <CircularProgress size={50} color="secondary" />
      </Box>
    );
  }

  // Если user не авторизован
  if (!user) {
    return (
      <Container sx={{ mt: 4 }}>
        <Typography variant="h6">Требуется авторизация</Typography>
      </Container>
    );
  }

  // Если у нас всё-таки идёт локальная загрузка (например, при повторном запросе),
  // можно дополнительно показать спиннер, но сама страница уже не «мерцает»,
  // т.к. initialLoading завершен.
  if (loading && !research) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="70vh">
        <CircularProgress size={50} color="secondary" />
      </Box>
    );
  }

  // Если нет исследования (например, 404 или нет доступа) после загрузки
  if (!research) {
    return (
      <Container sx={{ mt: 4 }}>
        <Typography variant="h5">Исследование не найдено или нет доступа</Typography>
      </Container>
    );
  }

  // Если все проверки пройдены, рендерим детальную страницу
  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Детали исследования #{researchId}
      </Typography>
      <Typography>Статус: {research.status}</Typography>
      {/* ... и т.д. выводим поля исследования ... */}
    </Container>
  );
};

export default ResearchDetail;
