// frontend/src/services/api.js
import axios from 'axios';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || '/api/',
  headers: { 'Content-Type': 'application/json' },
});

API.interceptors.request.use(
  config => {
    const token = localStorage.getItem('access_token');
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  error => Promise.reject(error)
);

API.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      localStorage.removeItem('access_token');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export const telegramAuth = data => API.post('users/telegram_auth/', null, { params: data });
export const telegramWebAppAuth = initData => API.post('users/telegram_webapp_auth/', { init_data: initData });
export const getDashboard = () => API.get('users/dashboard/');
export const updateSettings = settings => API.post('users/settings/', settings);
export const verifyPhone = () => API.post('users/verify_phone/');
export const updateUserPhone = phoneNumber => API.post('users/update_phone/', { phone_number: phoneNumber });
export const validateKey = (userData, uniqueKey) => API.post('users/validate_key/', { ...userData, unique_key: uniqueKey });
export const getMyOrgs = () => API.get('researches/my_orgs');
export const createOrg = payload => API.post('researches/create_org', payload);
export const updateOrg = (orgId, data) => API.put(`researches/update_org/${orgId}`, data);
export const getProducts = orgId => API.get(`researches/get_products/${orgId}`);
export const createProduct = payload => API.post('researches/create_product', payload);
export const updateProduct = (productId, data) => API.put(`researches/update_product/${productId}`, data);
export const getResearchTemplates = () => API.get('researches/templates');
export const getMyResearches = () => API.get('researches/my_researches');
export const createResearch = payload => API.post('researches/create_research', payload);
export const createResearchFromTemplate = (templateId, prodId) =>
  API.post('researches/create_research_from_template', { template_id: templateId, prod_id: prodId });
export const confirmTemplateDesign = payload => API.post('researches/confirm_template_design', payload);
export const getResearchById = researchId => API.get(`researches/get_research/${researchId}`);
export const updateResearchDesign = (researchId, updatedDesign) =>
  API.post(`researches/update_design/${researchId}`, updatedDesign);
// Новый метод для проверки/обновления брифа (при наличии edit_id)
export const verifyBrief = (researchId, briefData) => API.post(`researches/verify_brief/${researchId}`, briefData);

export default API;
