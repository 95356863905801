import React, { useState, useEffect, useContext, useMemo } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  Grid,
  Paper,
  Chip,
  Typography,
  Fade,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../context/AppContext';
import OrganizationStep from '../components/wizard/OrganizationStep';
import ProductStep from '../components/wizard/ProductStep';
import ResearchStep from '../components/wizard/ResearchStep';
import {
  getMyOrgs,
  createOrg,
  updateOrg,
  getProducts,
  createProduct,
  updateProduct,
  getResearchTemplates,
  createResearch,
  confirmTemplateDesign,
} from '../services/api';

const steps = ['Организация', 'Продукт', 'Исследование'];

const ResearchWizard = () => {
  const navigate = useNavigate();
  const { user, setNotification } = useContext(AppContext);
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);

  // Организации
  const [orgs, setOrgs] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);

  // Продукты
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  // Исследование
  const [templates, setTemplates] = useState([]);
  const [designMode, setDesignMode] = useState('template'); // 'template' или 'custom'
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [customBrief, setCustomBrief] = useState({
    rsr_objectives: '',
    rsr_problem: '',
    rsr_data: '',
    rsr_usage: '',
  });

  // Для отображения тегов в степпере
  const selectedOrgName = useMemo(
    () => orgs.find((o) => o.org_id === selectedOrg)?.company_name,
    [orgs, selectedOrg]
  );
  const selectedProductName = useMemo(
    () => products.find((p) => p.product_id === selectedProduct)?.product_name,
    [products, selectedProduct]
  );

  // Загрузка организаций и шаблонов
  useEffect(() => {
    if (!user) return;
    const fetchData = async () => {
      setLoading(true);
      try {
        const orgRes = await getMyOrgs();
        setOrgs(orgRes.data);
        const tplRes = await getResearchTemplates();
        setTemplates(tplRes.data);
      } catch (error) {
        setNotification({ type: 'error', text: 'Ошибка загрузки данных' });
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [user, setNotification]);

  // Загрузка продуктов при выборе организации
  useEffect(() => {
    if (selectedOrg) {
      const fetchProducts = async () => {
        try {
          const prodRes = await getProducts(selectedOrg);
          setProducts(prodRes.data);
        } catch (error) {
          setNotification({ type: 'error', text: 'Ошибка загрузки продуктов' });
        }
      };
      fetchProducts();
    } else {
      setProducts([]);
      setSelectedProduct(null);
    }
  }, [selectedOrg, setNotification]);

  // Колбэки для создания и обновления организации
  const handleCreateOrg = async (data) => {
    setLoading(true);
    try {
      const res = await createOrg(data);
      setNotification({ type: 'success', text: 'Организация создана!' });
      const orgRes = await getMyOrgs();
      setOrgs(orgRes.data);
      setSelectedOrg(res.data.org_id);
    } catch (error) {
      setNotification({
        type: 'error',
        text: error?.response?.data?.detail || 'Ошибка создания организации',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateOrg = async (orgId, data) => {
    setLoading(true);
    try {
      await updateOrg(orgId, data);
      setNotification({ type: 'success', text: 'Организация обновлена!' });
      const orgRes = await getMyOrgs();
      setOrgs(orgRes.data);
    } catch (error) {
      setNotification({
        type: 'error',
        text: error?.response?.data?.detail || 'Ошибка обновления организации',
      });
    } finally {
      setLoading(false);
    }
  };

  // Колбэки для создания и обновления продукта
  const handleCreateProduct = async (data) => {
    setLoading(true);
    try {
      const res = await createProduct({ ...data, org_id: selectedOrg });
      setNotification({ type: 'success', text: 'Продукт создан!' });
      const prodRes = await getProducts(selectedOrg);
      setProducts(prodRes.data);
      setSelectedProduct(res.data.product_id);
    } catch (error) {
      setNotification({
        type: 'error',
        text: error?.response?.data?.detail || 'Ошибка создания продукта',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateProduct = async (productId, data) => {
    setLoading(true);
    try {
      await updateProduct(productId, data);
      setNotification({ type: 'success', text: 'Продукт обновлён!' });
      const prodRes = await getProducts(selectedOrg);
      setProducts(prodRes.data);
    } catch (error) {
      setNotification({
        type: 'error',
        text: error?.response?.data?.detail || 'Ошибка обновления продукта',
      });
    } finally {
      setLoading(false);
    }
  };

  // Обработка перехода вперед
  const handleNext = async () => {
    if (activeStep === 0) {
      if (!selectedOrg) {
        setNotification({ type: 'error', text: 'Выберите организацию или создайте новую' });
        return;
      }
      setActiveStep(1);
    } else if (activeStep === 1) {
      if (!selectedProduct) {
        setNotification({ type: 'error', text: 'Выберите продукт или создайте новый' });
        return;
      }
      setActiveStep(2);
    } else if (activeStep === 2) {
      setLoading(true);
      try {
        if (designMode === 'template') {
          if (!selectedTemplate) {
            setNotification({ type: 'error', text: 'Выберите шаблон исследования' });
            return;
          }
          const payload = {
            template_id: selectedTemplate,
            prod_id: selectedProduct,
            modules_data: [],
          };
          const res = await confirmTemplateDesign(payload);
          setNotification({ type: 'success', text: res.data.message || 'Исследование создано!' });
          navigate(`/research/${res.data.research_id}`);
        } else {
          const { rsr_objectives, rsr_problem, rsr_data, rsr_usage } = customBrief;
          if (!rsr_objectives.trim() || !rsr_problem.trim() || !rsr_data.trim() || !rsr_usage.trim()) {
            setNotification({ type: 'error', text: 'Заполните все поля брифа' });
            return;
          }
          const res = await createResearch({
            prod_id: selectedProduct,
            rsr_objectives,
            rsr_problem,
            rsr_data,
            rsr_usage,
          });
          setNotification({ type: 'success', text: res.data.message || 'Исследование отправлено на проверку' });
          navigate(`/research/${res.data.research_id}`);
        }
      } catch (error) {
        setNotification({
          type: 'error',
          text: error?.response?.data?.detail || 'Ошибка создания исследования',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  // Обработка перехода назад с дополнительным сбросом выбранных значений
  const handleBack = () => {
    if (activeStep === 2) {
      // При возврате с шага исследования на продукт сбрасываем выбор шаблона и кастомный бриф
      setSelectedTemplate(null);
      setCustomBrief({ rsr_objectives: '', rsr_problem: '', rsr_data: '', rsr_usage: '' });
    } else if (activeStep === 1) {
      // При возврате с шага продукта на организацию сбрасываем выбранный продукт
      setSelectedProduct(null);
    }
    if (activeStep > 0) setActiveStep(activeStep - 1);
  };

  return (
    <Fade in={!loading}>
      <Grid container spacing={2}>
        {/* Вертикальный сайд-бар с степпером и тегами */}
        <Grid item xs={12} md={3}>
          <Paper sx={{ p: 2 }}>
            <Stepper orientation="vertical" activeStep={activeStep}>
              {steps.map((label, idx) => (
                <Step key={idx}>
                  <StepLabel>
                    {label}
                    {label === 'Организация' && selectedOrgName && (
                      <Chip label={selectedOrgName} size="small" sx={{ ml: 1 }} />
                    )}
                    {label === 'Продукт' && selectedProductName && (
                      <Chip label={selectedProductName} size="small" sx={{ ml: 1 }} />
                    )}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Paper>
        </Grid>
        {/* Контент шага */}
        <Grid item xs={12} md={9}>
          <Paper sx={{ p: 3 }}>
            {activeStep === 0 && (
              <OrganizationStep
                orgs={orgs}
                selectedOrg={selectedOrg}
                onSelectOrg={setSelectedOrg}
                onCreateOrg={handleCreateOrg}
                onUpdateOrg={handleUpdateOrg}
              />
            )}
            {activeStep === 1 && (
              <ProductStep
                products={products}
                selectedProduct={selectedProduct}
                onSelectProduct={setSelectedProduct}
                onCreateProduct={handleCreateProduct}
                onUpdateProduct={handleUpdateProduct}
              />
            )}
            {activeStep === 2 && (
              <ResearchStep
                templates={templates}
                designMode={designMode}
                setDesignMode={setDesignMode}
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
                customBrief={customBrief}
                setCustomBrief={setCustomBrief}
              />
            )}
            <Box mt={3} display="flex" justifyContent="space-between">
              <Button variant="outlined" onClick={handleBack} disabled={activeStep === 0}>
                Назад
              </Button>
              <Button variant="contained" onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Создать исследование' : 'Далее'}
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Fade>
  );
};

export default ResearchWizard;
