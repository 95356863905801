import React, { useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Chip,
  Button,
  TextField,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const ProductStep = ({ products, selectedProduct, onSelectProduct, onCreateProduct, onUpdateProduct }) => {
  const [openNew, setOpenNew] = useState(false);
  const [newProductData, setNewProductData] = useState({
    product_name: '',
    about_product: '',
    website: '',
  });

  const [openEdit, setOpenEdit] = useState(false);
  const [editProdData, setEditProdData] = useState({});

  const handleOpenNew = () => {
    setNewProductData({ product_name: '', about_product: '', website: '' });
    setOpenNew(true);
  };

  const handleSaveNew = async () => {
    await onCreateProduct(newProductData);
    setOpenNew(false);
  };

  const handleEdit = (prod) => {
    if (!prod.is_editable) return;
    setEditProdData(prod);
    setOpenEdit(true);
  };

  const handleSaveEdit = async () => {
    await onUpdateProduct(editProdData.product_id, editProdData);
    setOpenEdit(false);
  };

  return (
    <Box>
      <Typography variant="h6" mb={2}>
        Выберите продукт/услугу
      </Typography>
      {products.length ? (
        <Box display="flex" flexWrap="wrap" gap={2}>
          {products.map((prod) => (
            <Card
              key={prod.product_id}
              onClick={() => onSelectProduct(prod.product_id)}
              sx={{
                width: { xs: '100%', sm: 280 },
                cursor: 'pointer',
                border:
                  selectedProduct === prod.product_id ? '2px solid #1976d2' : '1px solid #e0e0e0',
                borderRadius: 2,
                boxShadow: selectedProduct === prod.product_id ? 4 : 1,
                position: 'relative',
                transition: 'all 0.2s',
                '&:hover': { boxShadow: 6 },
              }}
            >
              <CardContent>
                <Typography variant="subtitle1" fontWeight="bold">
                  {prod.product_name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {prod.about_product}
                </Typography>
                {prod.website && (
                  <Chip label={prod.website} size="small" sx={{ mt: 1 }} />
                )}
              </CardContent>
              {prod.is_editable && (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEdit(prod);
                  }}
                  size="small"
                  sx={{ position: 'absolute', top: 8, right: 8 }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              )}
            </Card>
          ))}
        </Box>
      ) : (
        <Typography>Нет доступных продуктов</Typography>
      )}
      <Box mt={2}>
        <Button variant="contained" onClick={handleOpenNew}>
          Добавить продукт/услугу
        </Button>
      </Box>

      {/* Диалог создания нового продукта */}
      <Dialog open={openNew} onClose={() => setOpenNew(false)}>
        <DialogTitle>Новый продукт/услуга</DialogTitle>
        <DialogContent>
          <TextField
            label="Название продукта/услуги"
            fullWidth
            margin="dense"
            value={newProductData.product_name}
            onChange={(e) =>
              setNewProductData({ ...newProductData, product_name: e.target.value })
            }
          />
          <TextField
            label="Описание продукта/услуги"
            fullWidth
            margin="dense"
            multiline
            rows={3}
            value={newProductData.about_product}
            onChange={(e) =>
              setNewProductData({ ...newProductData, about_product: e.target.value })
            }
          />
          <TextField
            label="Сайт"
            fullWidth
            margin="dense"
            value={newProductData.website}
            onChange={(e) =>
              setNewProductData({ ...newProductData, website: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenNew(false)}>Отмена</Button>
          <Button variant="contained" onClick={handleSaveNew}>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>

      {/* Диалог редактирования продукта */}
      <Dialog open={openEdit} onClose={() => setOpenEdit(false)}>
        <DialogTitle>Редактирование продукта/услуги</DialogTitle>
        <DialogContent>
          <TextField
            label="Название продукта/услуги"
            fullWidth
            margin="dense"
            value={editProdData.product_name || ''}
            onChange={(e) =>
              setEditProdData({ ...editProdData, product_name: e.target.value })
            }
          />
          <TextField
            label="Описание продукта/услуги"
            fullWidth
            margin="dense"
            multiline
            rows={3}
            value={editProdData.about_product || ''}
            onChange={(e) =>
              setEditProdData({ ...editProdData, about_product: e.target.value })
            }
          />
          <TextField
            label="Сайт"
            fullWidth
            margin="dense"
            value={editProdData.website || ''}
            onChange={(e) =>
              setEditProdData({ ...editProdData, website: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEdit(false)}>Отмена</Button>
          <Button variant="contained" onClick={handleSaveEdit}>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProductStep;
