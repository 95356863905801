import React from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

const ResearchStep = ({
  templates,
  designMode,
  setDesignMode,
  selectedTemplate,
  setSelectedTemplate,
  customBrief,
  setCustomBrief,
}) => {
  const handleModeSwitch = () => {
    setDesignMode(designMode === 'template' ? 'custom' : 'template');
    setSelectedTemplate(null);
    setCustomBrief({ rsr_objectives: '', rsr_problem: '', rsr_data: '', rsr_usage: '' });
  };

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button
          variant="outlined"
          startIcon={designMode === 'template' ? <DescriptionIcon /> : <LibraryBooksIcon />}
          onClick={handleModeSwitch}
        >
          {designMode === 'template'
            ? 'Перейти к кастомному брифу'
            : 'Использовать шаблон'}
        </Button>
      </Box>
      {designMode === 'template' ? (
        <Box>
          <Typography variant="h6" mb={2}>
            Выберите шаблон исследования
          </Typography>
          {templates.length ? (
            <Grid container spacing={2}>
              {templates.map((tpl) => (
                <Grid item xs={12} sm={6} md={4} key={tpl.template_id}>
                  <Card
                    elevation={selectedTemplate === tpl.template_id ? 4 : 1}
                    sx={{
                      cursor: 'pointer',
                      border:
                        selectedTemplate === tpl.template_id
                          ? '2px solid #1976d2'
                          : '1px solid #e0e0e0',
                      transition: 'all 0.2s',
                      '&:hover': { boxShadow: 6 },
                    }}
                    onClick={() => setSelectedTemplate(tpl.template_id)}
                  >
                    <CardContent>
                      {tpl.template_image && (
                        <Box
                          component="img"
                          src={tpl.template_image}
                          alt={tpl.template_name}
                          sx={{ width: '100%', mb: 1, borderRadius: 1 }}
                        />
                      )}
                      <Typography variant="subtitle1">{tpl.template_name}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        {tpl.template_description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography>Нет доступных шаблонов</Typography>
          )}
        </Box>
      ) : (
        <Box>
          <Typography variant="h6" mb={2}>
            Заполните бриф исследования
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Цели исследования"
                fullWidth
                multiline
                rows={3}
                value={customBrief.rsr_objectives}
                onChange={(e) => setCustomBrief({ ...customBrief, rsr_objectives: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Проблемы/вопросы"
                fullWidth
                multiline
                rows={3}
                value={customBrief.rsr_problem}
                onChange={(e) => setCustomBrief({ ...customBrief, rsr_problem: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Какие данные важны?"
                fullWidth
                multiline
                rows={3}
                value={customBrief.rsr_data}
                onChange={(e) => setCustomBrief({ ...customBrief, rsr_data: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Как будут использоваться результаты?"
                fullWidth
                multiline
                rows={3}
                value={customBrief.rsr_usage}
                onChange={(e) => setCustomBrief({ ...customBrief, rsr_usage: e.target.value })}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default ResearchStep;
