// frontend/src/context/AppContext.js
import React, { createContext, useState, useEffect, useCallback } from 'react';
import { getDashboard } from '../services/api';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [isTelegramWebApp, setIsTelegramWebApp] = useState(false);
  const [themeMode, setThemeMode] = useState('light');
  const [user, setUser] = useState(null);
  const [notification, setNotification] = useState(null);

  const toggleTheme = useCallback(newTheme => setThemeMode(newTheme), []);

  const fetchUserData = useCallback(async () => {
    const token = localStorage.getItem('access_token');
    if (token) {
      try {
        const response = await getDashboard();
        const fetchedUser = response.data;
        if (!user || user.theme !== fetchedUser.theme || user.is_phone_verified !== fetchedUser.is_phone_verified || user.phone !== fetchedUser.phone) {
          setUser(fetchedUser);
          setThemeMode(fetchedUser.theme || 'light');
        }
      } catch (error) {
        console.error('Ошибка при получении данных пользователя:', error);
      }
    }
  }, [user]);

  useEffect(() => {
    if (window.Telegram?.WebApp?.initData?.length > 0) {
      setIsTelegramWebApp(true);
      window.Telegram.WebApp.ready();
    }
    fetchUserData();
    const intervalId = setInterval(fetchUserData, 5000);
    return () => clearInterval(intervalId);
  }, [fetchUserData]);

  return (
    <AppContext.Provider value={{ 
      isTelegramWebApp, 
      themeMode, 
      toggleTheme, 
      user, 
      fetchUserData, 
      notification, 
      setNotification 
    }}>
      {children}
    </AppContext.Provider>
  );
};
