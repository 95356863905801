// frontend/src/pages/Dashboard.js
import React, { useEffect, useContext, useState, useMemo } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
  Tooltip,
  Drawer,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import FilterListIcon from '@mui/icons-material/FilterList';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../context/AppContext';
import { getMyResearches } from '../services/api';

const Dashboard = () => {
  const navigate = useNavigate();
  const { user, fetchUserData, setNotification } = useContext(AppContext);

  // Флаг «первоначальной загрузки» (для скрытия моргания)
  const [initialLoading, setInitialLoading] = useState(true);

  // Локальная загрузка списка исследований (когда мы делаем запрос getMyResearches)
  const [loading, setLoading] = useState(false);
  const [researches, setResearches] = useState([]);

  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [orgFilter, setOrgFilter] = useState('');
  const [productFilter, setProductFilter] = useState('');
  const [sortOrder, setSortOrder] = useState('desc');

  const [drawerOpen, setDrawerOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Если нужно «всегда» дергать fetchUserData
  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  // Если user === undefined, считаем, что ещё идёт проверка авторизации.
  // Как только user станет либо null, либо объектом, мы можем снять initialLoading.
  useEffect(() => {
    if (typeof user !== 'undefined') {
      // user уже загружен (даже если он null, это значит «точно не авторизован»)
      setInitialLoading(false);
    }
  }, [user]);

  // Загружаем список исследований после того, как точно знаем, что user — не undefined.
  useEffect(() => {
    if (!user || typeof user === 'undefined') return;

    setLoading(true);
    getMyResearches()
      .then((resp) => {
        setResearches(resp.data);
      })
      .catch((error) => {
        setNotification({
          type: 'error',
          text: error?.response?.data?.detail || 'Ошибка при загрузке исследований',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user, setNotification]);

  // Массив с исследованиями, у которых есть research_id
  const validResearches = useMemo(
    () => researches.filter((r) => r.research_id),
    [researches]
  );

  const uniqueStatuses = useMemo(() => {
    const setOfStatuses = new Set(validResearches.map((r) => r.status));
    return Array.from(setOfStatuses);
  }, [validResearches]);

  const uniqueOrgs = useMemo(() => {
    const setOfOrgs = new Set();
    validResearches.forEach((r) => {
      if (r.organization?.company_name) {
        setOfOrgs.add(r.organization.company_name);
      }
    });
    return Array.from(setOfOrgs);
  }, [validResearches]);

  const uniqueProducts = useMemo(() => {
    const setOfProds = new Set();
    validResearches.forEach((r) => {
      if (r.product?.product_name) {
        setOfProds.add(r.product.product_name);
      }
    });
    return Array.from(setOfProds);
  }, [validResearches]);

  const filteredAndSortedResearches = useMemo(() => {
    let items = [...validResearches];
    items = items.filter((r) => {
      const nameMatch = (r.rsr_name || 'Без названия')
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      const statusMatch = statusFilter ? r.status === statusFilter : true;
      const orgMatch = orgFilter
        ? r.organization?.company_name === orgFilter
        : true;
      const productMatch = productFilter
        ? r.product?.product_name === productFilter
        : true;
      return nameMatch && statusMatch && orgMatch && productMatch;
    });
    items.sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
    });
    return items;
  }, [validResearches, searchQuery, statusFilter, orgFilter, productFilter, sortOrder]);

  const resetAllFilters = () => {
    setSearchQuery('');
    setStatusFilter('');
    setOrgFilter('');
    setProductFilter('');
    setSortOrder('desc');
  };

  const handleDrawerToggle = () => {
    setDrawerOpen((prev) => !prev);
  };

  // 1) Если ещё не знаем, авторизован ли пользователь (user === undefined)
  //   => Показываем только крутилку
  if (initialLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
        <CircularProgress size={60} color="secondary" />
      </Box>
    );
  }

  // 2) Если user === null, то точно не авторизован
  if (!user) {
    return (
      <Container sx={{ mt: 4 }}>
        <Typography variant="h6">Требуется авторизация</Typography>
      </Container>
    );
  }

  // 3) Иначе (user !== null) мы авторизованы, можем показывать dashboard:
  return (
    <Container maxWidth="xl" sx={{ py: 3 }}>
      <Box
        sx={{
          mb: 2,
          display: 'flex',
          flexWrap: 'wrap',
          gap: 2,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 500 }}>
          Мои исследования
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
            gap: 2,
            justifyContent: 'flex-end',
          }}
        >
          <TextField
            size="small"
            variant="outlined"
            placeholder="Поиск..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
              endAdornment: searchQuery && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setSearchQuery('')}
                    edge="end"
                    size="small"
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ width: { xs: '100%', sm: 220, md: 250 } }}
          />

          <Tooltip title="Расширенные фильтры">
            <IconButton color="primary" onClick={handleDrawerToggle} sx={{ flexShrink: 0 }}>
              <FilterListIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Создать новое исследование">
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => navigate('/research-wizard')}
              sx={{ borderRadius: 2, whiteSpace: 'nowrap' }}
            >
              Новое исследование
            </Button>
          </Tooltip>
        </Box>
      </Box>

      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
          <CircularProgress size={40} color="secondary" />
        </Box>
      )}

      {!loading && filteredAndSortedResearches.length === 0 && (
        <Typography variant="body2" sx={{ mb: 2 }}>
          По заданным критериям исследований не найдено.
        </Typography>
      )}

      <Grid container spacing={3}>
        {filteredAndSortedResearches.map((rsr) => (
          <Grid item key={rsr.research_id} xs={12} sm={6} md={4} lg={3}>
            <Card
              onClick={() => navigate(`/research/${rsr.research_id}`)}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                position: 'relative',
                boxShadow: 2,
                borderRadius: 2,
                cursor: 'pointer',
                transition: 'all 0.3s ease',
                '&:hover': {
                  boxShadow: 4,
                  transform: 'translateY(-2px)',
                },
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  opacity: 0,
                  transition: 'opacity 0.3s ease',
                  color: 'grey.600',
                  '.MuiCard-root:hover &': {
                    opacity: 1,
                  },
                }}
              >
                <ChevronRightIcon />
              </Box>

              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  {rsr.rsr_name || 'Без названия'}
                </Typography>
                <Divider sx={{ mb: 1 }} />
                <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                  <strong>Статус:</strong> {rsr.status}
                </Typography>
                {rsr.organization?.company_name && (
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                    <strong>Организация:</strong> {rsr.organization.company_name}
                  </Typography>
                )}
                {rsr.product?.product_name && (
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                    <strong>Продукт:</strong> {rsr.product.product_name}
                  </Typography>
                )}
                {rsr.created_at && (
                  <Typography variant="body2" color="text.secondary">
                    <strong>Дата создания:</strong>{' '}
                    {new Date(rsr.created_at).toLocaleDateString('ru-RU')}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Drawer
        anchor={isMobile ? 'bottom' : 'right'}
        open={drawerOpen}
        onClose={handleDrawerToggle}
        PaperProps={{
          sx: {
            borderTopLeftRadius: isMobile ? 12 : 0,
            borderTopRightRadius: isMobile ? 12 : 0,
            width: isMobile ? '100%' : 300,
            maxHeight: isMobile ? '75vh' : '100%',
            p: 2,
          },
        }}
      >
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Фильтры и сортировка
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box>
            <Typography variant="body2" sx={{ mb: 0.5 }}>
              Статус:
            </Typography>
            <Select
              size="small"
              fullWidth
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">Все статусы</MenuItem>
              {uniqueStatuses.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box>
            <Typography variant="body2" sx={{ mb: 0.5 }}>
              Организация:
            </Typography>
            <Select
              size="small"
              fullWidth
              value={orgFilter}
              onChange={(e) => setOrgFilter(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">Все организации</MenuItem>
              {uniqueOrgs.map((org) => (
                <MenuItem key={org} value={org}>
                  {org}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box>
            <Typography variant="body2" sx={{ mb: 0.5 }}>
              Продукт:
            </Typography>
            <Select
              size="small"
              fullWidth
              value={productFilter}
              onChange={(e) => setProductFilter(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">Все продукты</MenuItem>
              {uniqueProducts.map((p) => (
                <MenuItem key={p} value={p}>
                  {p}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box>
            <Typography variant="body2" sx={{ mb: 0.5 }}>
              Сортировка по дате:
            </Typography>
            <Select
              size="small"
              fullWidth
              value={sortOrder}
              onChange={(e) => setSortOrder(e.target.value)}
            >
              <MenuItem value="desc">Сначала новые</MenuItem>
              <MenuItem value="asc">Сначала старые</MenuItem>
            </Select>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
            <Button variant="outlined" size="small" onClick={resetAllFilters}>
              Сбросить
            </Button>
            <Button variant="contained" size="small" onClick={handleDrawerToggle}>
              Применить
            </Button>
          </Box>
        </Box>
      </Drawer>
    </Container>
  );
};

export default Dashboard;
